import React from 'react'
import MyBackgroundImage from "../images/breadcrumb-bg2.jpg";
import ChooseBackgroundImage from "../images/chooseus-bg.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import room1 from '../images/dormitory-room.jpg'
import room2 from '../images/room1.1.jpg'
import room3 from '../images/room2.1.jpg'
import room4 from '../images/room-decor.jpg'
import abt2 from "../images/cottage.jpg";
import act1 from '../images/food.jpg';
import act2 from '../images/swimmimg.jpg';
import act3 from '../images/front-view-night.jpg';
import act4 from '../images/arrowgame.jpg';
import {Link} from 'react-router-dom'
import Footer from "./Footer";
import Meta from 'react-meta-tags';
const Gallery = () => {
  return (
    <div>

       {/* ---------------------------SEO-------------------- */}
       <Meta>
       <title>Family Resort In Palghar | The For-rest Resort</title>
<meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta
        property="og:title"
        content="Family Resort In Palghar | The For-rest Resort"
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://theforrestresort.in/" />
    
      <meta
        property="og:description"
        content="Discover a haven of excitement and relaxation at our Family Resort In Palghar. Enjoy unforgettable moments  and top-notch amenities."
      />



      <meta property="article:tag" content="
fertility hospital in mumbai" />
      <meta property="article:tag" content="resorts in palghar for couples" />
      <meta property="article:tag" content=" family resort in palghar" />
      <meta property="article:tag" content=" resort in palghar" />
      <meta
        property="article:tag"
        content=" resort in palghar for couples"
      />
      <meta property="article:tag" content="best resort in palghar" />
     
      <link rel="icon" href="../images/logo-removebg-preview-removebg-preview.png" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Discover a haven of excitement and relaxation at our Family Resort In Palghar. Enjoy unforgettable moments  and top-notch amenities.
      "
    />
    <meta
    name="keywords"
    content="best resort in palghar,
    resorts in palghar for couples,
    family resort in palghar,
    resort in palghar,
    resort in palghar for couples,
    palghar resort with swimming pool,
    best resort in palghar for family,
    palghar villa resort,
    best resort palghar,
    palghar side resort"
  />
  <meta
    name="title"
    content="Family Resort In Palghar | The For-rest Resort"
  />

  

<meta name="google-site-verification" content="HkZYGRnHOX_n1tjiS_V2hsitfAJpJ90gKuBM_FYKJrY" />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
    <link rel="canonical" href="http://theforrestresort.in/"/>
    </Meta>
     {/* ---------------------------SEO End------------------- */}
         <div
  className="breadcrumb-option set-bg"
  data-setbg="img/breadcrumb-bg.jpg"
  style={{ backgroundImage: `linear-gradient(to right, rgba(10, 9, 9, 0.5), rgb(0, 0, 0, 0.5)),url(${MyBackgroundImage})` }}
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center">
        <div className="breadcrumb__text">
          <h1>Family Resort In Palghar</h1>
          <div className="breadcrumb__links">
            <a href="./index.html">Home</a>
            <span>Gallery</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* ------------------------------Gallery---------------- */}
        <section className="gallery spad">
  <div className="gallery__text">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="section-title">
            <h5>OUR GALLERY</h5>
            <h2>Explore The Most Beautiful In The Hotel</h2>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="gallery__title">
            <p>
            Take a break with your family from your hectic life to unwind in the for-rest resort.
            </p>
            <Link to="/Rooms" className="primary-btn">
              Explore Rooms <span className="arrow_right" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Swiper
            // direction={"vertical"}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            breakpoints={{
              570: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
             <SwiperSlide>
             <div
          className="owl-item cloned"
        //   style={{ width: "248.5px", marginRight: 10 }}
        >
             <div
            className="gallery__item small__item set-bg"
            data-setbg="img/gallery/gallery-1.jpg"
            style={{ backgroundImage: `url(${room1})` }}
          />
          </div>
            </SwiperSlide>
            <SwiperSlide>
             <div
          className="owl-item cloned"
        //   style={{ width: "248.5px", marginRight: 10 }}
        >
             <div
            className="gallery__item small__item set-bg"
            data-setbg="img/gallery/gallery-1.jpg"
            style={{ backgroundImage: `url(${act1})` }}
          />
          </div>
            </SwiperSlide>
            <SwiperSlide>
             <div
          className="owl-item cloned"
        //   style={{ width: "248.5px", marginRight: 10 }}
        >
             <div
            className="gallery__item small__item set-bg"
            data-setbg="img/gallery/gallery-1.jpg"
            style={{ backgroundImage: `url(${room2})` }}
          />
          </div>
            </SwiperSlide>
            <SwiperSlide>
             <div
          className="owl-item cloned"
        //   style={{ width: "248.5px", marginRight: 10 }}
        >
             <div
            className="gallery__item small__item set-bg"
            data-setbg="img/gallery/gallery-1.jpg"
            style={{ backgroundImage: `url(${room3})` }}
          />
          </div>
            </SwiperSlide>
           <SwiperSlide>
           <div
          className="owl-item cloned"
        //   style={{ width: "248.5px", marginRight: 10 }}
        >
           <div
            className="gallery__item small__item set-bg"
            data-setbg="img/gallery/gallery-1.jpg"
            style={{ backgroundImage: `url(${act3})` }}
          />
          </div>
            </SwiperSlide>
            <SwiperSlide>
            <div
          className="owl-item cloned"
        //   style={{ width: "248.5px", marginRight: 10 }}
        >
            <div
            className="gallery__item small__item set-bg"
            data-setbg="img/gallery/gallery-1.jpg"
            style={{ backgroundImage: `url(${act2})` }}
          />
          </div>
            </SwiperSlide>
          </Swiper>
 

</section>


{/* ---------------------------things to do-------------------- */}
<section className="latest-blog spad">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title">
          <h5> Things to do</h5>
          <h2>In Our Resort</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 p-0 order-lg-1 col-md-6 order-md-1">
        <div
          className="latest__blog__pic set-bg"
          data-setbg="img/latest-blog/lb-1.jpg"
          style={{ backgroundImage: `url(${act2})` }}
        />
      </div>
      <div className="col-lg-3 p-0 order-lg-2 col-md-6 order-md-2">
        <div className="latest__blog__text">
          <div className="label">Swimming Pool</div>
          <h5>Splash with water</h5>
          <p>
          Relax into the easy-going at our outdoor swimming pool
            {/* <i className="fa fa-clock-o" /> 19th March, 2019 */}
          </p>
        
        </div>
      </div>
      <div className="col-lg-3 p-0 order-lg-3 col-md-6 order-sm-4">
        <div
          className="latest__blog__pic set-bg"
          data-setbg="img/latest-blog/lb-2.jpg"
          style={{ backgroundImage: `url(${act4})` }}
        />
      </div>
      <div className="col-lg-3 p-0 order-lg-4 col-md-6 order-sm-3">
        <div className="latest__blog__text">
          <div className="label">Indoor-outdoor games</div>
          <h5>Lots of activities</h5>
          <p>
          We have a wide variety of activities like outdoor and indoor games and packages in resort.
            {/* <i className="fa fa-clock-o" /> 22th March, 2019 */}
          </p>
         
        </div>
      </div>
      <div className="col-lg-3 p-0 order-lg-6 col-md-6 order-md-5">
        <div
          className="latest__blog__pic latest__blog__pic__last__row set-bg"
          data-setbg="img/latest-blog/lb-3.jpg"
          style={{ backgroundImage: `url(${act1})` }}
        />
      </div>
      <div className="col-lg-3 p-0 order-lg-5 col-md-6 order-md-6">
        <div className="latest__blog__text">
          <div className="label">Delicious food</div>
          <h5>Various Food items</h5>
          <p>
          We present delicious food something tasty just for you surrounded by lush greenery. 
            {/* <i className="fa fa-clock-o" /> 25th March, 2019 */}
          </p>
         
        </div>
      </div>
      <div className="col-lg-3 p-0 order-lg-8 col-md-6 order-md-8">
        <div
          className="latest__blog__pic latest__blog__pic__last__row set-bg"
          data-setbg="img/latest-blog/lb-4.jpg"
          style={{ backgroundImage: `url(${act3})` }}
        />
      </div>
      <div className="col-lg-3 p-0 order-lg-7 col-md-6 order-md-7">
        <div className="latest__blog__text">
          <div className="label">Cottage</div>
          <h5>Home Comfort</h5>
          <p>Achieve a Perfect Balance of At-Home Comfort With Accommodations that Fit Your Style.

          </p>
     
        </div>
      </div>
    </div>
  </div>
</section>
<Footer/>
    </div>
  )
}

export default Gallery