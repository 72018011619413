import React from 'react'
import '../css/Footer.css';
import {Link} from 'react-router-dom'
import { HashLink } from "react-router-hash-link";
import FooterBackgroundImage from "../images/footer-bg.jpg";
const Footer = () => {
  return (
    <div>

<footer
  className="footer set-bg"
  data-setbg="img/footer-bg.jpg"
  style={{ backgroundImage: `url(${ FooterBackgroundImage})` }}
>
  
  <div className="container">
    <div className="footer__content">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="footer__about">
            <div className="footer__logo">
              <a href="#">
                <img src="img/logo.png" alt="" />
              </a>
            </div>
            <h4>082081 93797</h4>
            <ul>
              <li>Bhavangad Fort, road, Tighare, Maharashtra 401401</li>
              <li>shreyaspramodpatil@gmail.com</li>
            </ul>
           
          </div>
        </div>
        <div className="col-lg-4  col-md-5  col-sm-6">
          <div className="footer__widget">
            <h4>Quick Link</h4>
            <ul>
            <li className="active">
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/discover-the-best-resort-palghar">Rooms</Link>
                </li>
                <li>
                  <Link to="/resort-in-palghar-for-couples">About Us</Link>
                </li>
                
                <li>
                  <Link to="/family-resort-in-palghar">Gallery</Link>
                </li>
               
           
          
            </ul>
            <ul>
            <li className="active">
                  <HashLink to="#explore">Explore Resort</HashLink>
                </li>
                <li>
                  <HashLink to="#testi">Testimonials</HashLink>
                </li>
                <li>
                  <HashLink to="#todo">Things to do</HashLink>
                </li>
                
              
                <li>
                   <Link to="/palghar-resort-with-swimming-pool">Contact</Link>
                </li>
             
            </ul>
          </div>
        </div>
        <div className="col-lg-4 col-md-8 col-sm-12">
          <div className="footer__newslatter">
            <h4>Visit Us</h4>
            {/* <form action="#">
              <input type="text" placeholder="Your E-mail Address" />
              <button type="submit">Subscribe</button>
            </form> */}
            <div className="footer__newslatter__find footer__about">
              <h5>Find Us:</h5>
              <div className="footer__social">
              <a href="https://www.facebook.com/profile.php?id=100083053491437">
                <i className="fa fa-facebook" />
              </a>
              <a href="https://www.instagram.com/the_forrestresort/?next=%2Fcrystal.clear.eye%2F">
                <i className="fa fa-instagram" />
              </a>
              <a href="https://goo.gl/maps/K6KM4LddQMBGy7QDA">
                <i className="fa fa-google" />
              </a>
              <a href="#">
                <i className="fa fa-youtube-play" />
              </a>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer__copyright">
      <div className="row">
        <div className="col-lg-7 col-md-7">
          <div className="footer__copyright__text">
            <p>
              Copyright ©  The Forest Resort 2023 All rights reserved | <br/>
              {/* <i className="fa fa-heart" aria-hidden="true" /> by{" "} */}
              {/* <a href="https://skdm.in" target="_blank">
                Shree Krishna Digital Marketing
              </a> */}
            </p>
          </div>
        </div>
        <div className="col-lg-5 col-md-5">
        <div className="footer__copyright__text">
        <a href="https://skdm.in" target="_blank">
             Developed By Shree Krishna Digital Marketing
              </a>
              </div>
          {/* <ul className="footer__copyright__links">
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#">Contact</a>
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Footer