import React from 'react'
import MyBackgroundImage from "../images/breadcrumb-bg1.jpg";
import Footer from './Footer';
import Meta from 'react-meta-tags';
const Contact = () => {
  return (
    <div>
 {/* ---------------------------SEO-------------------- */}
 <Meta>
 <title>Palghar Resort With Swimming Pool | The For-rest Resort</title>
<meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta
        property="og:title"
        content="Palghar Resort With Swimming Pool | The For-rest Resort"
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://theforrestresort.in/" />
    
      <meta
        property="og:description"
        content="Experience the epitome of relaxation at Palghar Resort With Swimming Pool. Escape the city's hustle and bustle and rejuvenate your senses in this idyllic oasis. "
      />



      <meta property="article:tag" content="
fertility hospital in mumbai" />
      <meta property="article:tag" content="resorts in palghar for couples" />
      <meta property="article:tag" content=" family resort in palghar" />
      <meta property="article:tag" content=" resort in palghar" />
      <meta
        property="article:tag"
        content=" resort in palghar for couples"
      />
      <meta property="article:tag" content="best resort in palghar" />
     
      <link rel="icon" href="../images/logo-removebg-preview-removebg-preview.png" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Experience the epitome of relaxation at Palghar Resort With Swimming Pool. Escape the city's hustle and bustle and rejuvenate your senses in this idyllic oasis. "
    />
    <meta
    name="keywords"
    content="best resort in palghar,
    resorts in palghar for couples,
    family resort in palghar,
    resort in palghar,
    resort in palghar for couples,
    palghar resort with swimming pool,
    best resort in palghar for family,
    palghar villa resort,
    best resort palghar,
    palghar side resort"
  />
  <meta
    name="title"
    content="Palghar Resort With Swimming Pool | The For-rest Resort"
  />

  

<meta name="google-site-verification" content="HkZYGRnHOX_n1tjiS_V2hsitfAJpJ90gKuBM_FYKJrY" />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
    <link rel="canonical" href="http://theforrestresort.in/"/>
    </Meta>
     {/* ---------------------------SEO End------------------- */}

<div
  className="breadcrumb-option set-bg"
  data-setbg="img/breadcrumb-bg.jpg"
  style={{ backgroundImage: `url(${MyBackgroundImage})` }}
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center">
        <div className="breadcrumb__text">
          <h1>Palghar Resort With Swimming Pool </h1>
          <div className="breadcrumb__links">
            <a href="./index.html">Home</a>
            <span>Contact Us</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
        <section className="contact spad">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-md-6 col-sm-5">
        <div className="contact__widget">
          <div className="contact__widget__item">
            <h4>Contact Us</h4>
            <ul>
              <li>82081 93797</li>
              <li>shreyaspramodpatil@gmail.com</li>
            </ul>
          </div>
          <div className="contact__widget__item">
            <h4>Address</h4>
            <p>
            Bhavangad Fort, road, Tighare, Maharashtra 401401
            </p>
          </div>
          <div className="contact__widget__time">
            <h4>Opentime</h4>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="contact__widget__time__item">
                  <ul>
                    <li>Monday - Friday</li>
                    <li>
                      <span>8 am - 9 pm</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="contact__widget__time__item">
                  <ul>
                    <li>Saturday - Sunday</li>
                    <li>
                      <span>8 am - 9 pm</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 offset-lg-1 col-md-6 col-sm-7">
        <div className="contact__form">
          <h2>Your question?</h2>
          <form action="#">
            <input type="text" placeholder="Your Name" />
            <input type="text" placeholder="Email" />
            <textarea placeholder="Your Message" defaultValue={""} />
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer/>
    </div>
  )
}

export default Contact