import Navbar from './component/Navbar'
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './component/Home';
import About from './component/About';
import Gallery from './component/Gallery';
import Rooms from './component/Rooms';
import Contact from './component/Contact';
import './App.css';
const App = () => {
  return (
    <BrowserRouter>
      <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>}/> 
          <Route path='/resort-in-palghar-for-couples' element={<About/>} />
          <Route path='/family-resort-in-palghar' element={<Gallery/>} />
          <Route path='/discover-the-best-resort-palghar' element={<Rooms/>} />
          <Route path='/palghar-resort-with-swimming-pool' element={<Contact/>} />
        </Routes>
      </BrowserRouter>
  )
}

export default App;
