import React, {useState} from 'react'
import MyBackgroundImage from "../images/breadcrumb-bg.jpg";
import ChooseBackgroundImage from "../images/chooseus-bg.jpg";
import swim from "../images/swimmimg.jpg"
import dine from "../images/dinning.jpg"
import room from "../images/dormitory-room.jpg"
import park from "../images/parking.jpg"
import Footer from "./Footer";
import Bounce from 'react-reveal/Bounce';
import s1 from '../images/services-1.png.webp'
import s2 from '../images/services-2.png.webp'
import s3 from '../images/services-3.png.webp'
import s4 from '../images/services-4.png.webp'
import s5 from '../images/services-5.png.webp'
import s6 from '../images/services-6.png.webp'
import Meta from "react-meta-tags";

const About = () => {
  return (
    <div>
 {/* ---------------------------SEO-------------------- */}
 <Meta>
 <title> Resort In Palghar For Couples | The For-rest Resort </title>
<meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta
        property="og:title"
        content=" Resort In Palghar For Couples | The For-rest Resort"
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://theforrestresort.in/" />
    
      <meta
        property="og:description"
        content="Experience a blissful retreat at our luxurious  Resort In Palghar For Couples . Indulge in romantic moments and create unforgettable memories with your partner. "
      />



      <meta property="article:tag" content="
fertility hospital in mumbai" />
      <meta property="article:tag" content="resorts in palghar for couples" />
      <meta property="article:tag" content=" family resort in palghar" />
      <meta property="article:tag" content=" resort in palghar" />
      <meta
        property="article:tag"
        content=" resort in palghar for couples"
      />
      <meta property="article:tag" content="best resort in palghar" />
     
      <link rel="icon" href="../images/logo-removebg-preview-removebg-preview.png" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Experience a blissful retreat at our luxurious  Resort In Palghar For Couples . Indulge in romantic moments and create unforgettable memories with your partner. 
      "
    />
    <meta
    name="keywords"
    content="best resort in palghar,
    resorts in palghar for couples,
    family resort in palghar,
    resort in palghar,
    resort in palghar for couples,
    palghar resort with swimming pool,
    best resort in palghar for family,
    palghar villa resort,
    best resort palghar,
    palghar side resort"
  />
  <meta
    name="title"
    content="Resort In Palghar For Couples | The For-rest Resort"
  />

  

<meta name="google-site-verification" content="HkZYGRnHOX_n1tjiS_V2hsitfAJpJ90gKuBM_FYKJrY" />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
    <link rel="canonical" href="http://theforrestresort.in/"/>
    </Meta>
     {/* ---------------------------SEO End------------------- */}

       <div
  className="breadcrumb-option set-bg"
  data-setbg="img/breadcrumb-bg.jpg"
  style={{ backgroundImage: `linear-gradient(to right, rgba(10, 9, 9, 0.5), rgb(0, 0, 0, 0.5)),url(${MyBackgroundImage})` }}
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center">
        <div className="breadcrumb__text">
          <h1>Resort In Palghar For Couples  </h1>
          <div className="breadcrumb__links">
            <a href="./index.html">Home</a>
            <span>About Us</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* ------------------------------About us---------------- */}
<section className="about spad">
  <div className="container">
    <div className="about__content">
      <div className="row">
        <div className="col-lg-5">
          <div className="section-title">
            <h5>Our Specialization</h5>
            <h2>Welcome For-rest Resort</h2>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="about__text">
            <p>
            Just 2 hrs away from Mumbai , situated at the bottom of Bhavangad Fort with serene views of lush green for-rest.     </p>
            <p>
            For-rest resort that invites you to discover the difference pride can make in your next vacation. Ours is a family resort, owned and operated with a spirit of pride in offering the finest and warm hospitality to our guests.
            </p>
          </div>
        </div>
      </div>
    </div>
  
<div className="row">
      <div className="col-lg-4 col-md-4 col-sm-6">
        <div className="services__item">
          <Bounce top>
          <img src={s1} alt="free-wifi-at-resort" />
          </Bounce>
          <h4>Free Wi-Fi</h4>
          <p>
          We understand how important it is to remain connected with nature as well as with your near and dear ones. Free WiFi will make you feel connected without hassle and you can share your enjoyment with stories and VCs.
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-6">
        <div className="services__item">
        <Bounce top>
          <img src={s2} alt="premium-swimming-pool-for-rest" />
          </Bounce>
          <h4>Premium Pool</h4>
          <p>
          Relax into the easy-going at our outdoor swimming pool
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-6">
        <div className="services__item">
        <Bounce top>
          <img src={s3} alt="tea-coffee-for-rest" />
          </Bounce>
          <h4>Tea/Coffee</h4>
          <p>
          Enjoy a freshly prepared Tea or coffee 
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-6">
        <div className="services__item">
        <Bounce top>
          <img src={s4} alt="drinks-at-for-rest" />
          </Bounce>
          <h4>Drinks</h4>
          <p>
          For-rest Resort offers a variety of drinks options for every occasion.
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-6">
        <div className="services__item">
        <Bounce top>
          <img src={s5} alt="hd-tv-at-for-rest" />
          </Bounce>
          <h4>TV HD</h4>
          <p>
          Relax and enjoy in our resort with Television.
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-6">
        <div className="services__item">
        <Bounce top>
          <img src={s6} alt="fine-dine-at-for-rest" />
          </Bounce>
          <h4>Fine Dine</h4>
          <p>
          Your dining options are as diverse as the opportunities you’ll have to enjoy them.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


{/* Why choose---------------------------------------------- */}

<div
  className="chooseus spad set-bg"
  data-setbg="img/chooseus-bg.jpg"
  style={{ backgroundImage: `url(${ChooseBackgroundImage})` }}
>
  <div className="container">
    <div className="row d-flex justify-content-center">
      <div className="col-lg-8 text-center">
        <div className="chooseus__text">
          <div className="section-title">
            <h5>WHY CHOOSE US</h5>
            <h2>
              Contact us now to get the latest deals and for the next booking
            </h2>
          </div>
          <a href="#" className="primary-btn">
            Booking Now
          </a>
        </div>
      </div>
    </div>
  </div>
</div>


<section className="history spad">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title history-title">
          <h5>Our History</h5>
          <h2>Explore Our Hotel</h2>
        </div>
      </div>
    </div>
    <div className="history__content">
      <div className="row">
        <div className="col-lg-5 col-md-5">
          <div className="history__item left__item">
            <div className="history__date" />
            <span>First Floor</span>
            <h4>Swimming Pool</h4>
            <img
              data-cfsrc="img/history/history-1.jpg"
              alt="first-floor-swimming-pool"
              src={swim}
            />
            <p>
             
A swimming pool is often an essential requirement when choosing the perfect property for your family holiday. Relax and take a dip into the easy-going at our outdoor swimming pool
            </p>
          </div>
          <div className="history__item left__item mb-0">
            <div className="history__date" />
            <span>Dine with US </span>
            <h4>Fine Dine</h4>
            <img
              data-cfsrc="img/history/history-3.jpg"
              alt="fine-dine-at-for-rest"
              src={dine}
            />
            <p>
            For-rest Resort dining is convenient and full of options to suit your taste, budget and busy schedule. you’ll find something to treat your appetite right at for-rest resort .

            </p>
          </div>
        </div>
        <div className="col-lg-5 offset-lg-2 col-md-5 offset-md-2">
          <div className="history__item right__first__item">
            <div className="history__date" />
            <span>Hall</span>
            <h4>Dormitory Rooms</h4>
            <img
              data-cfsrc="img/history/history-2.jpg"
              alt="dormitory-rooms-at-for-rest"
              src={room}
            />
            <p>
            An awesome vacation starts with the perfect room. We know you’re here to have fun, relax, and maybe play around a little.If you’re looking for a room for family or Fri , our dormitory room combine the rustic charm of our surroundings with modern features.
            </p>
          </div>
          <div className="history__item mb-0">
            <div className="history__date" />
            <span>Space available</span>
            <h4>Parking</h4>
            <img
              data-cfsrc="img/history/history-4.jpg"
              alt="parking-at-for-rest"
              src={park}
            />
            <p>
            For-rest resort accommodates spacious parking area for your convenience we make sure that your vehicle managed and effortlessly parked. Ensure your and your vehicle safety in resort.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer/>

    </div>
  )
}

export default About